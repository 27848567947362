import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {
    PageSection,
    Grid,
    GridHeader,
    InspirationCluster,
} from '@ingeniorforeningen/design-system';
import EditPageButton from '../EditPageButton';

export interface IRelatedContent {
    Data: string;
    Id: number;
}

export type TRelatedContentProps = {
    relatedContent: IRelatedContent;
    showEditLink: boolean;
    onFullWidthContentVisibilityChange?: any;
};

const RelatedContent: React.FC<TRelatedContentProps> = ({
    relatedContent,
    showEditLink,
    onFullWidthContentVisibilityChange,
}) => {
    const [show, setShow] = useState(false);
    if (relatedContent && relatedContent.Data && show !== true) {
        setShow(true);
    }

    return (
        <>
            {show && (
                <PageSection>
                    <Grid container spacing={40}>
                        <GridHeader heading="Læs mere" />
                        <EditPageButton
                            showEditLink={showEditLink}
                            nodeId={relatedContent.Id}
                        />
                        <VisibilitySensor
                            onChange={onFullWidthContentVisibilityChange}
                            partialVisibility={true}
                        >
                            <InspirationCluster
                                rows={JSON.parse(relatedContent.Data)}
                            />
                        </VisibilitySensor>
                    </Grid>
                </PageSection>
            )}
        </>
    );
};

export default RelatedContent;
