import {
    AnchorMenu,
    Button,
    ContactBand,
    Grid,
    Heading,
    Icons,
    Link,
    Page,
    PageSection,
    Paragraph,
    SectionAccordion,
    SectionHeader,
    TopCoverImage,
    MultiIconList,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import EditPageButton from '../Components/EditPageButton';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import VisibilitySensor from 'react-visibility-sensor';
import smoothscroll from 'smoothscroll-polyfill';
import CourseLocationSignup from '../Signup/CourseLocationSignup';
import UmbracoGrid from '../UmbracoGrid';
import { IMeetupPageProps } from './MeetupPage';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';
import { FloatingGrid } from '../Components/FloatingGrid';
import { CourseSignup, ICourseModule } from '../Components/CourseSignup';
import sustainableDevelopmentGoalsIcons from '../utils/sustainableDevelopmentGoalFactory';
import { track } from '../Analytics';

export interface IContactOld {
    Image: string;
    Name: string;
    Occupation: string;
    Phone: string;
    Email: string;
    IsValid: boolean;
}

export interface IContact {
    Image: string;
    Name: string;
    Occupation: string;
    Phone: string;
    Email: string;
    IsValid: boolean;
}

export interface IMeetupUrl {
    MeetupNumber: string;
    CityName: string;
    Date: string;
    Url: string;
    HasWaitingList: boolean;
}

export interface IAnchorMenuItem {
    Label: string;
    Hash: string;
}

export interface ICoursePageProps {
    Meetup?: IMeetupPageProps;
    Header: string;
    Teaser: string;
    IntroImage: string;
    GridDescription: any;
    GridProgram: any;
    MeetupUrls: IMeetupUrl[];
    ContactOld: IContactOld;
    Contact: IContact;
    MeetupNumbers: string[];
    NoMeetupText: string;
    BindingSignup: boolean;
    classes?: any;
    AnchorMenu: IAnchorMenuItem[];
    CourseOverviewPageUrl: string;
    CourseLocations: any;
    Upcoming: IUpcoming[];
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
    RelatedContent: IRelatedContent;
    SustainableDevelopmentGoalsUmbracoNodeId: number;
}

export interface IUpcoming {
    availableSeats: number;
    statusCode: number;
    startDate: string;
    city: string;
    geographicalLocation: string;
}

export interface ICoursePageState {
    currentMeetup: any;
    hideForFullWidthContent: boolean;
    signUpButtonIsOutOfViewport: boolean;
    signupAreaRef: any;
    selectedOccurrenceIndex: any;
    scroll: boolean;
}

// we only need the windowInnerHeight on client side - guard for SSR
const windowInnerHeight =
    typeof window !== 'undefined' ? window.innerHeight : 1;
const googleApiKey = 'AIzaSyBWw7G1WkPqai9MWq25fJuDaePeD2qNayc';

const styles: StyleSheetThemed = (theme) => ({
    noMargin: {
        margin: 0,
    },
    sectionMarginTop: {
        marginTop: '5px',
    },
    sectionMarginBottom: {
        marginBottom: '10px',
    },
    inlinePageContainers: {
        paddingTop: '40px',
        paddingBottom: '40px',

        [theme.breakpoints.up('md')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        },
    },
    priceWrapperMargin: {
        marginBottom: '15px',
    },
    priceWrapperStylingObject: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
        lineHeight: '22px',
        maxWidth: '380px',

        '& p': {
            margin: 0,
            fontSize: '16px',
            lineHeight: '30px',
        },

        [theme.breakpoints.up('md')]: {
            fontSize: '16px',
            lineHeight: '30px',
        },
    },
    textTag: {
        fontWeight: 600,
        color: theme.colors.brandPrimary,
        fontSize: '14px',
        lineHeight: '32px',
        margin: 0,

        [theme.breakpoints.up('md')]: {
            fontSize: '16px',
            lineHeight: '19px',
            margin: '0 0 9px 0',
        },
    },
    textTitle: {
        marginTop: 0,
        fontSize: '30px',
        lineHeight: '45px',
        marginBottom: '9px',

        [theme.breakpoints.up('md')]: {
            marginBottom: '36px',
        },
    },
    meetupPage: {
        color: 'inherit',
    },

    introPadding: {
        paddingTop: '0 !important',
        '$meetupPage &': {
            [theme.breakpoints.up('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },

    breadCrumbWrapper: {
        padding: '10px 0 !important',
        [theme.breakpoints.up('md')]: {
            padding: '0 !important',
        },
    },

    breadCrumbLink: {
        textDecoration: 'underline',
    },

    backArrow: {
        transform: 'rotate(90deg)',
        marginBottom: '-7px',
        marginLeft: '-10px',
        marginRight: '5px',
    },
    bindingSignupText: {
        marginBottom: 0,
    },
    signupAreaScrollPositionWrapper: {
        position: 'relative',
    },
    signupAreaScrollPositionAnchor: {
        position: 'absolute',
        top: `-${windowInnerHeight / 2 - 100}px`,
    },
    signupButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    stickySignupButton: {
        boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.5)',
        position: 'fixed',
        bottom: '0',
        right: '0',
        marginBottom: '1em',
        marginRight: '5.5em',
        transform: 'translateY(0.5em)',
        transition: 'all ease 0.2s',
        opacity: '0',
        zIndex: '100',

        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    stickySignupButtonShow: {
        transform: 'translateY(0em)',
        opacity: '1',
    },
    sdgViewbox: {
        marginTop: '0',
        width: '100%',
        textAlign: 'center',
    },
});

export interface IWrapperProps {
    classes?: any;
}

class CoursePage extends React.Component<ICoursePageProps, ICoursePageState> {
    public state: ICoursePageState;

    constructor(props) {
        super(props);
        this.state = {
            signUpButtonIsOutOfViewport: false,
            hideForFullWidthContent: false,
            currentMeetup: this.props.Meetup
                ? this.props.Meetup.EventNumber
                : null,
            signupAreaRef: React.createRef(),
            selectedOccurrenceIndex: this.props.CourseLocations.map(() => {
                return 0;
            }),
            scroll: false,
        };

        this.scrollToSignupArea = this.scrollToSignupArea.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
    }

    onLocationChange = (locationIndex, occurrenceIndex) => {
        const selectedOccurrenceIndex = [...this.state.selectedOccurrenceIndex];
        selectedOccurrenceIndex[locationIndex] = occurrenceIndex;

        this.setState({
            selectedOccurrenceIndex,
        });
    };

    componentDidMount() {
        smoothscroll.polyfill();
        window.addEventListener('scroll', this.handleScroll);
        if (this.props.Meetup) {
            track().ecommerce.viewItem({
                id: this.props.Meetup.EventNumber,
                affiliation: 'lokation ikke valgt',
                category: 'kursus',
                host: this.props.Meetup.OrganizerName,
                name: this.props.Meetup.Title,
                price: this.props.Meetup.FromPrice,
                activityType: this.props.Meetup.ActivityTypeEnumValue,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    deadline = () => {
        const smallerFontSize = { fontSize: '14px' };
        if (this.props.Meetup) {
            // Hvis kursus har flere end 3 ledige pladser eller er udsolgt vises tilmeldingsfrist
            if (
                this.props.Meetup.AvailableSeats > 3 ||
                this.props.Meetup.AvailableSeats === 0
            ) {
                return (
                    <p style={smallerFontSize}>
                        Tilmeldingsfrist{' '}
                        {this.props.Meetup.FormattedRegistrationDeadline}
                    </p>
                );
            } else if (this.props.Meetup.AvailableSeats > 0) {
                return <p style={smallerFontSize}>Få ledige pladser</p>;
            }
        }
    };

    location = () => {
        if (this.props.Meetup) {
            let locationText = `${this.props.Meetup.MeetupLocationName}, ${this.props.Meetup.City}`;
            if (this.props.Meetup.Type === 'Webinar') {
                locationText = 'Online Arrangement';
            }
            if (!this.props.Meetup.City) {
                locationText = this.props.Meetup.MeetupLocationName;
            }
            if (!this.props.Meetup.MeetupLocationName) {
                locationText = this.props.Meetup.City;
            }

            return <p style={{ fontWeight: 600 }}>{locationText}</p>;
        }
    };

    topPrice = () => {
        if (this.props.Meetup) {
            if (this.props.Meetup.TopPrice === null) {
                return null;
            }
            if (this.props.Meetup.TopPrice === 'Gratis') {
                return (
                    <p style={{ fontWeight: 600 }}>
                        {this.props.Meetup.TopPrice}
                    </p>
                );
            } else {
                return (
                    <p style={{ fontWeight: 600 }}>
                        {this.props.Meetup.TopPrice} ekskl. moms
                    </p>
                );
            }
        }
    };

    coOrganizersElement = () => {
        if (
            !this.props.Meetup ||
            !this.props.Meetup.CoOrganizers ||
            this.props.Meetup.CoOrganizers.length === 0
        ) {
            return null;
        }
        const coOrganizers = this.props.Meetup.CoOrganizers.map(
            (coOrganizer, index) => {
                return (
                    <div key={index}>
                        <p>{coOrganizer}</p>
                    </div>
                );
            }
        );

        return (
            <div>
                <p style={{ fontWeight: 600 }}>Medarrangører</p>
                {coOrganizers}
            </div>
        );
    };

    onFullWidthContentVisibilityChange = (isVisible) => {
        if (this.props.Meetup) {
            this.setState({ hideForFullWidthContent: isVisible });
        }
    };

    onSignupButtonVisibilityChange = (isVisible) => {
        if (this.props.Meetup) {
            this.setState({ signUpButtonIsOutOfViewport: !isVisible });
        }
    };

    scrollToSignupArea = (event) => {
        event.preventDefault();

        if (this.state.signupAreaRef && this.state.signupAreaRef.current) {
            this.state.signupAreaRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    handleScroll = () => {
        if (document.documentElement.scrollTop > 0) {
            this.setState({ scroll: true });
        } else {
            this.setState({ scroll: false });
        }
    };

    render() {
        const { classes } = this.props;
        const anchorMenu = this.props.AnchorMenu
            ? this.props.AnchorMenu.map((item, index) => {
                  return {
                      href: item.Hash,
                      title: item.Label,
                  };
              })
            : [];

        const gridWidthSwap =
            this.props.CourseLocations && this.props.CourseLocations.length > 0
                ? 12
                : 6;
        let durationString = '';
        let prices;
        const signupModules = [] as ICourseModule[];
        let optionPrices;
        let optionPricesExists;
        let priceInfo;
        let sustainableDevelopmentGoalsModule;

        const { Meetup } = this.props;

        if (Meetup) {
            if (Meetup.Prices) {
                prices = () => {
                    return Meetup.Prices.map((price, i) => {
                        return (
                            <div
                                key={`price${i}`}
                                className={classes.priceWrapperStylingObject}
                            >
                                <p>{price.Name}:</p>
                                <p>{price.Price}</p>
                            </div>
                        );
                    });
                };
            }

            if (Meetup.OptionPrices) {
                optionPricesExists = Meetup.OptionPrices.length > 0;
                optionPrices = () =>
                    Meetup.OptionPrices.map((optionPrice, i) => {
                        return (
                            <div
                                key={`price${i}`}
                                className={classes.priceWrapperStylingObject}
                            >
                                <p>{optionPrice.Name}:</p>
                                <p>{optionPrice.Price}</p>
                            </div>
                        );
                    });
            }

            if (
                Meetup.ModulesWrapper &&
                Meetup.ModulesWrapper.TotalDurationInDays
            ) {
                durationString =
                    Meetup.ModulesWrapper.TotalDurationInDays > 1
                        ? `${Meetup.ModulesWrapper.TotalDurationInDays} dage`
                        : `${Meetup.ModulesWrapper.TotalDurationInDays} dag`;
            }
            sustainableDevelopmentGoalsModule = () => {
                if (
                    Meetup.SustainableDevelopmentGoals &&
                    Meetup.SustainableDevelopmentGoals.length >= 1
                ) {
                    return (
                        <React.Fragment>
                            <Grid
                                item
                                className={this.props.classes.sdgViewbox}
                            >
                                <EditPageButton
                                    showEditLink={this.props.ShowEditLink}
                                    nodeId={
                                        Meetup.SustainableDevelopmentGoalsUmbracoNodeId
                                    }
                                />
                                <MultiIconList
                                    title={Meetup.SustainableDevelopmentTitle}
                                    iconList={sustainableDevelopmentGoalsIcons(
                                        Meetup.SustainableDevelopmentGoals,
                                        Meetup.Language
                                    )}
                                    richtext={Meetup.SustainableDevelopmentText}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                }
            };
        }

        if (this.props.CourseLocations) {
            priceInfo = () => {
                if (this.props.CourseLocations.length > 0) {
                    return (
                        <React.Fragment>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                style={{ padding: 0 }}
                            >
                                <p className={classes.noMargin}>
                                    <strong>Varighed</strong>
                                </p>
                                <p className={classes.sectionMarginTop}>
                                    {durationString}
                                </p>
                                <p className={classes.noMargin}>
                                    <strong>Pris ekskl. moms</strong>
                                </p>
                                <div className={classes.priceWrapperMargin}>
                                    {prices && prices()}
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                style={{ padding: 0 }}
                            >
                                {optionPricesExists && (
                                    <p className={classes.noMargin}>
                                        <strong>Tilvalg</strong>
                                    </p>
                                )}
                                {optionPricesExists && optionPrices()}
                            </Grid>
                        </React.Fragment>
                    );
                }
            };

            this.props.Upcoming.forEach((meetup) => {
                signupModules.push(meetup);
            });
        }

        return (
            <React.Fragment>
                <FeatureFlag show={this.props.FeatureFlags.ShowAnchorMenu}>
                    <AnchorMenu items={anchorMenu} />
                </FeatureFlag>

                <Page
                    condensed
                    hasAnchorMenu={this.hasAnchorMenu()}
                    hasTopImage={Boolean(this.props.IntroImage)}
                >
                    {this.props.IntroImage && (
                        <TopCoverImage src={this.props.IntroImage} />
                    )}
                    <PageSection>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                className={this.props.classes.breadCrumbWrapper}
                            >
                                <FeatureFlag
                                    show={
                                        this.props.FeatureFlags
                                            .ShowCoursesBackButton
                                    }
                                >
                                    <Link
                                        url={this.props.CourseOverviewPageUrl}
                                        className={
                                            this.props.classes.breadCrumbLink
                                        }
                                    >
                                        <Icons.ArrowDown
                                            className={
                                                this.props.classes.backArrow
                                            }
                                        />
                                        Kursusoversigt
                                    </Link>
                                </FeatureFlag>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className={this.props.classes.introPadding}
                            >
                                <p className={this.props.classes.textTag}>
                                    Kursus
                                </p>
                                <Heading
                                    is="h1"
                                    size={600}
                                    className={this.props.classes.textTitle}
                                >
                                    {this.props.Header}
                                    <EditPageButton
                                        showEditLink={this.props.ShowEditLink}
                                        nodeId={this.props.Id}
                                    />
                                </Heading>
                                <Paragraph half noMargin>
                                    {this.props.Teaser}
                                </Paragraph>

                                {Boolean(this.props.Upcoming.length) && (
                                    <>
                                        <br />
                                        <Button
                                            id="signupButton"
                                            buttonType="primary"
                                            className={
                                                this.props.classes.signupButton
                                            }
                                            version="cta"
                                            wide
                                            onClick={this.scrollToSignupArea}
                                        >
                                            Gå til tilmelding
                                        </Button>
                                    </>
                                )}
                            </Grid>
                            <FloatingGrid
                                xs={false}
                                sm={3}
                                appear={
                                    this.state.signUpButtonIsOutOfViewport &&
                                    !this.state.hideForFullWidthContent
                                }
                            >
                                {Boolean(this.props.Upcoming.length) && (
                                    <>
                                        <CourseSignup
                                            modules={signupModules}
                                            anchorLink="#tilmelding"
                                            duration={durationString}
                                        />
                                    </>
                                )}
                            </FloatingGrid>
                            <Grid item xs={`none`} sm={3}></Grid>
                        </Grid>
                    </PageSection>

                    <UmbracoGrid data={this.props.GridDescription} />

                    <PageSection condensed alternate>
                        <Grid container>
                            {sustainableDevelopmentGoalsModule &&
                                sustainableDevelopmentGoalsModule()}
                        </Grid>
                    </PageSection>

                    <PageSection>
                        <Grid container>
                            <Grid item xs={12}>
                                <div
                                    className={
                                        this.props.classes
                                            .signupAreaScrollPositionWrapper
                                    }
                                >
                                    <Grid container justify="flex-start">
                                        <Grid
                                            item
                                            slim
                                            xs={12}
                                            sm={gridWidthSwap}
                                            md={gridWidthSwap}
                                        >
                                            <SectionHeader hash="tilmelding">
                                                Tilmelding
                                            </SectionHeader>
                                        </Grid>
                                        {this.props.CourseLocations &&
                                            priceInfo &&
                                            priceInfo()}
                                    </Grid>
                                    <Grid container justify="center">
                                        <Grid
                                            item
                                            xs={12}
                                            sm={gridWidthSwap}
                                            md={gridWidthSwap}
                                            style={{ padding: 0 }}
                                        >
                                            {this.props.CourseLocations &&
                                                Boolean(
                                                    this.props.CourseLocations
                                                        .length
                                                ) && (
                                                    <p
                                                        className={
                                                            this.props.classes
                                                                .sectionMarginBottom
                                                        }
                                                    >
                                                        <strong>
                                                            Vælg sted og dato
                                                        </strong>
                                                    </p>
                                                )}
                                            <div
                                                ref={this.state.signupAreaRef}
                                                className={
                                                    this.props.classes
                                                        .signupAreaScrollPositionAnchor
                                                }
                                            />
                                            <VisibilitySensor
                                                onChange={
                                                    this
                                                        .onSignupButtonVisibilityChange
                                                }
                                                partialVisibility={true}
                                            >
                                                <React.Fragment>
                                                    {this.props
                                                        .CourseLocations && (
                                                        <SectionAccordion
                                                            items={this.props.CourseLocations.map(
                                                                (
                                                                    location,
                                                                    index
                                                                ) => ({
                                                                    icon: 'location',
                                                                    heading:
                                                                        location.locationName,
                                                                    headingHelpText:
                                                                        'Se datoer',
                                                                    body: (
                                                                        <CourseLocationSignup
                                                                            onLocationChange={
                                                                                this
                                                                                    .onLocationChange
                                                                            }
                                                                            selectedOccurrenceIndex={
                                                                                this
                                                                                    .state
                                                                                    .selectedOccurrenceIndex[
                                                                                    index
                                                                                ]
                                                                            }
                                                                            googleApiKey={
                                                                                googleApiKey
                                                                            }
                                                                            index={
                                                                                index
                                                                            }
                                                                            location={
                                                                                location
                                                                            }
                                                                        />
                                                                    ),
                                                                })
                                                            )}
                                                        />
                                                    )}
                                                    {(!this.props
                                                        .CourseLocations ||
                                                        this.props
                                                            .CourseLocations
                                                            .length === 0) && (
                                                        <Button
                                                            buttonType="secondary"
                                                            disabled
                                                            version="cta"
                                                            wide
                                                            style={{
                                                                margin: '20px auto',
                                                                display:
                                                                    'block',
                                                            }}
                                                        >
                                                            Nye datoer kommer
                                                            snarest
                                                        </Button>
                                                    )}
                                                </React.Fragment>
                                            </VisibilitySensor>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </PageSection>

                    <UmbracoGrid data={this.props.GridProgram} />

                    <RelatedContent
                        relatedContent={this.props.RelatedContent}
                        showEditLink={this.props.ShowEditLink}
                        onFullWidthContentVisibilityChange={
                            this.onFullWidthContentVisibilityChange
                        }
                    />

                    {Boolean(
                        this.props.Upcoming.length &&
                            this.state.signUpButtonIsOutOfViewport &&
                            !this.state.hideForFullWidthContent
                    ) && (
                        <Button
                            id="floatingSignupButton"
                            buttonType="primary"
                            version="cta"
                            className={`${classes.stickySignupButton} ${
                                this.state.scroll
                                    ? classes.stickySignupButtonShow
                                    : null
                            }`}
                            onClick={this.scrollToSignupArea}
                        >
                            Gå til tilmelding
                        </Button>
                    )}
                </Page>
                {Boolean(this.props.ContactOld.IsValid) && (
                    <PageSection alternate>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <SectionHeader
                                    hash="vil-du-vide-mere"
                                    invisible
                                >
                                    Vil du vide mere
                                </SectionHeader>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <ContactBand {...this.props.ContactOld} />
                        </Grid>
                    </PageSection>
                )}
                {Boolean(this.props.Contact.IsValid) && (
                    <PageSection alternate>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <SectionHeader
                                    hash="vil-du-vide-mere"
                                    invisible
                                >
                                    Vil du vide mere
                                </SectionHeader>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <ContactBand {...this.props.Contact} />
                        </Grid>
                    </PageSection>
                )}
            </React.Fragment>
        );
    }

    private hasAnchorMenu = () => {
        return (
            this.props.AnchorMenu &&
            this.props.AnchorMenu.length &&
            this.props.FeatureFlags.ShowAnchorMenu
        );
    };
}

export default injectSheet(styles)(CoursePage);
