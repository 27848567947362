import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import { Icons } from '@ingeniorforeningen/design-system';

type TProps = {
    classes: any;
};

type TState = {
    scroll: boolean;
};

const styles = {
    buttonWrapper: {
        cursor: 'pointer',
        backgroundColor: 'white',
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        right: '0',
        marginBottom: '1em',
        marginRight: '1em',
        width: '3em',
        height: '3em',
        borderRadius: '3em',
        boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.5)',
        '& svg': {
            width: '0.5em!important',
            height: '0.5em',
            margin: 'auto',
            '& path': {
                strokeWidth: '0.25em',
            },
        },
        transform: 'translateY(0.5em)',
        transition: 'all ease 0.2s',
        opacity: '0',
    },
    show: {
        transform: 'translateY(0em)',
        opacity: '1',
    },
};

class ScrollToTopButton extends React.Component<TProps, TState> {
    constructor(props: any) {
        super(props);
        this.state = {
            scroll: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (document.documentElement.scrollTop > 0) {
            this.setState({ scroll: true });
        } else {
            this.setState({ scroll: false });
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <div
                    className={`${classes.buttonWrapper} ${
                        this.state.scroll ? classes.show : null
                    }`}
                    onClick={() =>
                        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
                    }
                >
                    <Icons.ArrowUp />
                </div>
            </>
        );
    }
}

export default injectSheet(styles)(ScrollToTopButton);
