import React from 'react';
import { Paper, Link, Button } from '@ingeniorforeningen/design-system';
import CourseItems from './CourseItems';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import { MeetupStatus } from '../../Enums/MeetupStatus';

export interface ICourseModule {
    statusCode: MeetupStatus;
    availableSeats: number;
    startDate: string;
    city: string;
}

export type TCourseSignupProps = {
    modules: ICourseModule[];
    duration: string;
    anchorLink: string;
    classes?: any;
};

const styles: StyleSheetThemed = (theme) => ({
    courseSignupWrapper: {
        backgroundColor: 'hsl(223, 86%, 43%)',
        color: 'white',
        '& h3': {
            textAlign: 'center',
            marginTop: '0',
            fontSize: '16px',
        },
        '& p': {
            fontSize: '14px',
        },
        '& a': {
            textDecoration: 'none',
        },
    },
    toMany: {
        margin: '0',
    },
});

const CourseSignup: React.FC<TCourseSignupProps> = ({
    classes,
    modules,
    duration,
    anchorLink,
}) => {
    const hasManyModules = modules.length > 3;

    return (
        <Paper className={classes.courseSignupWrapper}>
            <h3 className={classes.title}>Tid og sted</h3>
            <CourseItems items={modules} />
            {hasManyModules && (
                <p className={classes.toMany}>
                    Se flere tider og steder under tilmelding
                </p>
            )}
            <p>Varighed: {duration}</p>
            <div>
                <Link url={anchorLink}>
                    <Button
                        buttonType="primary"
                        version="cta"
                        noMargin
                        noPadding
                    >
                        Gå til tilmelding
                    </Button>
                </Link>
            </div>
        </Paper>
    );
};

export default injectSheet(styles)(CourseSignup);
