import {
    AppShell,
    Footer,
    Header,
    TFooterProps,
    THeaderProps
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactDOMServer from 'react-dom/server';

import ArticlePage from './Pages/ArticlePage';
import ConferencePage from './Pages/ConferencePage';
import CourseCategoryPage from './Pages/CourseCategoryPage';
import CourseOverviewPage from './Pages/CourseOverviewPage';
import CoursePage from './Pages/CoursePage';
import DictionaryPage from './Pages/DictionaryPage';
import EmployeesPage from './Pages/EmployeesPage';
import Frontpage from './Pages/Frontpage';
import GenericOverviewPage from './Pages/GenericOverviewPage';
import InfoPage from './Pages/InfoPage';
import VideoPage from './Pages/VideoPage';
import PodcastPage from './Pages/PodcastPage';
import MeetupFrontpage from './Pages/MeetupFrontpage';
import MeetupPage from './Pages/MeetupPage';
import MemberSignupPage from './Pages/MemberSignupPage';
import NetworkOverviewPage from './Pages/NetworkOverviewPage';
import NetworkPage from './Pages/NetworkPage';
import NewsOverviewPage from './Pages/NewsOverviewPage';
import PageNotFound from './Pages/PageNotFound';
import CludoSearchPage from './Pages/CludoSearchPage';
import SectionFrontpage from './Pages/SectionFrontpage';
import ThemeOverviewPage from './Pages/ThemeOverviewPage';
import ThemePage from './Pages/ThemePage';
import TopicPage from './Pages/TopicPage';
import ScrollToTopButton from './Components/ScrollToTopButton';
import BreadcrumbWrapper from './Components/Breadcrumb';
import { IFeatureFlags, FeatureFlag } from './Components/FeatureFlag';
import uncapitalizeObject from './utils/uncapitalizeObject';
import { track, initTracker } from './Analytics';

export interface IAppProps {
    TemplateId: string;
    [propName: string]: any; // index signature to allow any other props.
    MitIdaBaseUrl: string;
    ContactHeader: string;
    PhoneLabel: string;
    PhoneUrl: string;
    MailLabel: string;
    MailUrl: string;
    CvrLabel: string;
    FacebookUrl: string;
    LinkedInUrl: string;
    TwitterUrl: string;
    InstagramUrl: string;
    PrivacyLinkLabel: string;
    PrivacyLinkUrl: string;
    TopMenu: any;
    LeftLinkList: any;
    RightLinkList: any;
    FeatureFlags: IFeatureFlags;
    Campaign?: any;
    Footer: any;
}

interface ITemplateMap {
    [propName: string]: any;
}

function templatePicker(templateId: string) {
    const templateMap: ITemplateMap = {
        frontpage: Frontpage,
        articlePage: ArticlePage,
        infoPage: InfoPage,
        videoPage: VideoPage,
        podcastPage: PodcastPage,
        genericOverviewPage: GenericOverviewPage,
        meetupFrontpage: MeetupFrontpage,
        meetupPage: MeetupPage,
        networkOverviewPage: NetworkOverviewPage,
        networkPage: NetworkPage,
        cludoSearchPage: CludoSearchPage,
        sectionFrontpage: SectionFrontpage,
        themeOverviewPage: ThemeOverviewPage,
        themePage: ThemePage,
        topicPage: TopicPage,
        pageNotFound: PageNotFound,
        newsOverviewPage: NewsOverviewPage,
        dictionaryPage: DictionaryPage,
        conferencePage: ConferencePage,
        coursePage: CoursePage,
        courseCategoryPage: CourseCategoryPage,
        courseOverviewPage: CourseOverviewPage,
        employeesPage: EmployeesPage,
        memberSignupPage: MemberSignupPage
    };
    const template = templateMap[templateId];

    // If the template is not found or is not a function
    if (!template) {
        throw new Error('Not a valid template (' + templateId + ')');
    }

    return template;
}

class App extends React.Component<IAppProps> {
    // Remove the server-side injected CSS.
    constructor(props) {
        super(props);
        initTracker(props.useGtmProdContainer);
    }

    public componentDidMount() {
        track().general.pageView({
            nodeId: this.props.Id,
            pageTitle: this.props.Title,
            section: this.props.Section
        });
        const jssStyles = document.getElementById('server-side-styles');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }

    public render() {
        const Page = templatePicker(this.props.TemplateId);
        const topMenu = uncapitalizeObject(this.props.TopMenu);
        // We remap the Footer and TopMenu to match the props from DS components.
        // TODO: have matching object straight from models
        const footer: TFooterProps = {
            menu: {
                primary: this.props.Footer.LeftLinkList.map(item => {
                    return {
                        label: item.Title,
                        url: item.Url,
                        target: item.Target
                    };
                }),
                secondary: this.props.Footer.RightLinkList.map(item => {
                    return {
                        label: item.Title,
                        url: item.Url,
                        target: item.Target
                    };
                })
            },
            policies: {
                privacyLabel: this.props.Footer.PrivacyLinkLabel,
                privacyLink: this.props.Footer.PrivacyLinkUrl
            },
            contact: {
                contactLabel: this.props.Footer.ContactHeader,
                phone: this.props.Footer.PhoneLabel,
                mailLabel: this.props.Footer.MailLabel,
                mailLink: this.props.Footer.MailUrl
            },
            socials: {
                facebook: this.props.Footer.FacebookUrl,
                twitter: this.props.Footer.TwitterUrl,
                linkedin: this.props.Footer.LinkedInUrl,
                instagram: this.props.Footer.InstagramUrl
            }
        };

        const header: THeaderProps = {
            selectedGlobalHeaderSite: 'IDA.DK',
            campaignBar: this.props.Campaign,
            navbar: {
                homeUrl: '/',
                options: [
                    {
                        icon: 'Search',
                        label: 'Søg',
                        url: '/soeg',
                        showOnMobile: false
                    },
                    {
                        icon: 'FlagukWhite',
                        label: 'EN',
                        url: 'https://english.ida.dk/',
                        showOnMobile: false
                    },
                    {
                        icon: 'NavMember',
                        label: `Mit IDA`,
                        url: 'https://mit.ida.dk/login',
                        showOnMobile: true
                    }
                ]
            },
            navigation: {
                options: {
                    primary: topMenu.primaryNavigation.map(item => {
                        return {
                            label: item.title,
                            url: item.url,
                            target: item.target,
                            children: item.children.map(child => {
                                return {
                                    label: child.title,
                                    url: child.url,
                                    target: child.target
                                };
                            })
                        };
                    }),
                    secondary: topMenu.secondaryNavigation.map(item => {
                        return {
                            label: item.title,
                            url: item.url,
                            target: item.target,
                            children: item.children.map(child => {
                                return {
                                    label: child.title,
                                    url: child.url,
                                    target: child.target
                                };
                            })
                        };
                    })
                },
                footer: {
                    left: footer.menu.primary,
                    right: footer.menu.secondary
                }
            }
        };

        return (
            <AppShell>
                <Header {...header} />
                <FeatureFlag show={this.props.FeatureFlags.ShowBreadcrumb}>
                    <BreadcrumbWrapper breadcrumbs={this.props.Breadcrumbs} />
                </FeatureFlag>
                {/* Pass all props to the page */}
                <div style={{ minHeight: 'calc(100vh - 300px)' }} id="content">
                    <Page {...this.props} />
                </div>
                <Footer {...footer} />

                <ScrollToTopButton />
            </AppShell>
        );
    }
}
// tslint:disable-next-line:no-string-literal
global['React'] = React;
// tslint:disable-next-line:no-string-literal
global['ReactDOM'] = ReactDOM;
// tslint:disable-next-line:no-string-literal
global['ReactDOMServer'] = ReactDOMServer;
// tslint:disable-next-line:no-string-literal
global['App'] = App;

export default App;
